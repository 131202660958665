import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionInvoiceService {
    readProductionInvoiceQuery() {
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetProductionInvoice (Paging:$paging, Status:$status) {
                    ProductionInvoiceReponse {
                        created_at
                        last_update
                        invoice_id
                        invoice_number
                        status
                        invoice_date
                        invoice_due_date
                        invoice_received_date
                        ppn_tax_percentage
                        currency_id
                        currency_name
                        exchange_rate
                        notes
                        created_by
                        production_id
                        producer_id
                        producer_name
                        production_invoice_detail {
                            created_at
                            last_update
                            invoice_id
                            item_id
                            item_name
                            type_detail_id
                            product_code
                            uom
                            discount
                            quantity
                            price_per_unit
                            price
                            vat_per_unit
                            vat
                        }
                    }
                    total
                } 
            }
        `;
        return query;
    }

    async getProductionInvoiceDetailQuery(id){
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int!) {
                GetProductionInvoice (InvoiceId:$id) {
                    ProductionInvoiceReponse {
                        created_at
                        last_update
                        invoice_id
                        invoice_number
                        status
                        invoice_date
                        invoice_due_date
                        invoice_received_date
                        ppn_tax_percentage
                        currency_id
                        currency_name
                        exchange_rate
                        notes
                        created_by
                        production_id
                        production_code
                        producer_id
                        producer_name
                        production_invoice_detail {
                            created_at
                            last_update
                            invoice_id
                            item_id
                            item_name
                            type_detail_id
                            product_code
                            uom
                            discount
                            quantity
                            price_per_unit
                            price
                            vat_per_unit
                            vat
                        }
                    }
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProductionInvoice.ProductionInvoiceReponse[0];
    }

    getProductionInvoiceAbleQuery(){
        var query = `
            query ($paging:ServerPaging) {
                GetProduction (Paging:$paging, ProuductionType:"2", status:"On Progress") {
                    ProductionResponse {
                        status
                        production_id
                        production_code
                        production_result_item_id
                        production_result_product_code
                        production_result_item_name
                        production_result_uom
                        production_result_quantity
                        production_type
                        created_at
                        last_update
                        waste_percentage
                        storage_id
                        machine_id
                        machine_name
                        booking_order_id
                        producer_id
                        producer_name
                    }
                    Total
                }
            }            
        `;
        return query;
    }
    
    async getProductionInvoiceDetailData(id) {
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int!) {
                GetProduction (ProductionId:$id) {
                    ProductionResponse {
                        status
                        production_id
                        production_code
                        production_result_item_id
                        production_result_product_code
                        production_result_item_name
                        production_result_uom
                        production_result_quantity
                        production_type
                        created_at
                        last_update
                        waste_percentage
                        storage_id
                        machine_id
                        machine_name
                        booking_order_id
                        producer_id
                        producer_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProduction.ProductionResponse[0];
    }
    
    async getCurrencyDropdown() {
        var query = gql`
            query {
                GetCurrency {
                    id
                    currency_code
                    currency_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetCurrency != null) {
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { 
                    value: result.data.GetCurrency[i].id, 
                    label: result.data.GetCurrency[i].currency_name + " (" + result.data.GetCurrency[i].currency_code + ")"
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    materialGridDataBuilder(data, saveType, tax) {
        var result = [];

        if (data != null) {
            if (saveType == "Add") {
                var str = {
                    item_id: data.production_result_item_id,
                    product_code: data.production_result_product_code,
                    item_name: data.production_result_item_name,
                    uom: data.production_result_uom,
                    quantity: data.production_result_quantity,
                    discount: 0,
                    price_per_unit: 0,
                    price: 0,
                    vat_per_unit: 0,
                    vat: 0,
                }
            }
            else if (saveType == "Edit") {                    
                var str = {
                    item_id: data[0].item_id,
                    product_code: data[0].product_code,
                    item_name: data[0].item_name,
                    uom: data[0].uom,
                    quantity: data[0].quantity,
                    discount: data[0].discount,
                    price_per_unit: data[0].price_per_unit,
                    price: data[0].price,
                    vat_per_unit: data[0].vat_per_unit,
                    vat: data[0].vat,
                }
            }
            else if (saveType == "Tax") {
                var disc = data[0].price_per_unit * (data[0].discount / 100);
                var ppn = data[0].price_per_unit * (tax / 100);

                var str = {
                    item_id: data[0].item_id,
                    product_code: data[0].product_code,
                    item_name: data[0].item_name,
                    uom: data[0].uom,
                    quantity: data[0].quantity,
                    discount: data[0].discount,
                    price_per_unit: data[0].price_per_unit,
                    price: data[0].price_per_unit * data[0].quantity,
                    vat_per_unit: data[0].price_per_unit - disc + ppn,
                    vat: (data[0].price_per_unit - disc + ppn) * data[0].quantity,
                }
            }
            result.push(str);
        }

        return result;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: NewProductionInvoice!) {
                CreateProductionInvoice(NewProductionInvoice: $data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($id:Int!, $data: NewProductionInvoice!) {
                UpdateProductionInvoice(InvoiceId:$id, NewProductionInvoice:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String!) {
                DeleteFormula( FormulaId: $id )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                item_id: data[i].item_id,
                item_name: data[i].item_name,
                stock: data[i].stock,
                qty_change: data[i].qty_change,
                type_detail_id: data[i].type_detail_id
            }
            array.push(str);
        }
        return array;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }
            else {
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }
        else {
            if(selected == '') {
                var newData = [];
                return newData;
            }
            else {
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

}

export default new ProductionInvoiceService();