<template>
    <div>
        <router-view></router-view>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Production Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <production-invoice-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick"/>
                <production-invoice-create-form ref="ProductionInvoiceCreateForm" :reload="reload"/>
                <production-invoice-status-form ref="ProductionInvoiceStatusForm" :reload="reload" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import productionInvoiceGrid from './Grid/ProductionInvoiceGrid.vue';
import productionInvoiceCreateForm from './Component/ProductionInvoiceCreateForm.vue';
import productionInvoiceServices from './Script/ProductionInvoiceServices.js';
import productionInvoiceStatusForm from '../ProductionInvoice/Component/ProductionInvoiceStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ProductionInvoice',
    components: {
        'production-invoice-grid': productionInvoiceGrid,
        'production-invoice-create-form': productionInvoiceCreateForm,
        'production-invoice-status-form': productionInvoiceStatusForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Production Invoice');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: ''
        }
    },
    methods: {
        addClick(){
            this.$refs.ProductionInvoiceCreateForm.addClick();
        },
        editClick(data, view){
            // this.$router.push({ name: 'Production Invoice Form', params: {  formtype:'Edit', id:data, view:view } });
            const routeData = this.$router.resolve({ name: 'Production Invoice Form', params: {  formtype:'Edit', id:data, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await productionInvoiceServices.getProductionInvoiceDetailQuery(id);

            var detailArray = globalfunc.objectToArrayConverter(data.production_invoice_detail, "ProductionInvoice-ItemDetail");
            
            const productionInvData = {
                status: 'Delete',
                production_id: data.production_id,
                invoice_date: data.invoice_date,
                invoice_due_date: data.invoice_due_date,
                invoice_received_date: data.invoice_received_date,
                ppn_tax_percentage: data.ppn_tax_percentage,
                currency_id: data.currency_id,
                exchange_rate: data.exchange_rate,
                notes: data.notes,
                production_invoice_detail: detailArray
            }
            
            this.$swal(sweet_alert.delete).then((result) => {
               if (result.isConfirmed == true) {
                    
                    const variables = {
                        id : parseInt(id),
                        data : productionInvData
                    };
                    
                    productionInvoiceServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");  
                    }).catch(error => {
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.ProductionInvoiceStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_production_invoice', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnPosting").on('click', function() {
                    vue.$refs.grid.changeStatus('Posting');
                });
                $("#btnPayment").on('click', function() {
                    vue.$refs.grid.changeStatus('Payment');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>